import axiosInstance from '../../plugins/axios';
import i18n from '@/lang/lang';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';

export default {
    namespaced: true,
    state: {
        cards: {
            choicesByCardId: {} // Map by cardId key with the array of card choices for this cardId
        }
    },
    getters: {
        /*
        getUserAnswersByCardId: (state) => state.cards.userAnswersByCardId,
         getPostsOrdered: (state, getters, rootState, rootGetters) => {
            const lang = rootGetters["languages/getLang"];

            return state.posts
                .sort((a, b) => parseInt(a.attributes.order) > parseInt(b.attributes.order) ? 1 : -1)
                .filter(post => {
                    if (post.attributes.lang === lang) return true;
                });
        }, */
    },
    mutations: {
        setCardChoices(state, { cardId, choices }) {
            state.cards.choicesByCardId[cardId] = choices;
        }
    },
    actions: {
        /**
         * @param {String|Number} cardId
         *
         * @returns {Promise} with a card choice array
         */
        async getCardChoices({ dispatch, state, commit }, { cardId }) {
            try {
                let choices = state.cards.choicesByCardId[cardId];
                if (!choices?.length) {
                    const response = await EvaluationsAPIService.getChoicesFromCard({
                        cardId
                    });

                    choices = response.data.choices;
                    commit('setCardChoices', { cardId, choices });
                }

                return choices;
            } catch (e) {
                dispatch('snackbar/error', i18n.t('common.errorLoadingData'), {
                    root: true
                });

                return null;
            }
        }
    }
};
