<template>
    <v-btn
        :block="block"
        :rounded="rounded"
        :text="text"
        :outlined="outlined"
        :tile="tile"
        :nuxt="nuxt"
        :to="to"
        :link="link"
        :color="color || getCompany.color_primary || 'default_icons_color'"
        :icon="icon"
        :class="actualClass"
        :x-small="xSmall"
        :small="small"
        :large="large"
        :x-large="xLarge"
        :dark="dark"
        :light="light"
        :disabled="disabled"
        @click="$emit('click', $event)"
        @click.prevent="$emit('click.prevent', $event)"
        @click.stop="$emit('click.stop', $event)"
        :min-width="minWidth"
    >
        {{ title }}
        <slot />
        <v-icon
            v-if="mdiIcon"
            :right="!!title"
        >
            {{ mdiIcon }}
        </v-icon>
    </v-btn>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        nuxt: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false
        },
        to: {
            type: String,
            default: undefined
        },
        color: {
            type: String,
            default: undefined
        },
        text: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: true
        },
        classes: {
            type: String,
            default: undefined
        },
        nopx: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: false
        },
        icon: {
            type: Boolean,
            default: false
        },
        mdiIcon: {
            type: String,
            default: undefined
        },
        link: {
            type: String,
            default: ''
        },
        small: {
            type: Boolean,
            default: false
        },
        xSmall: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        xLarge: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        minWidth: {
            type: String
        }
    },
    computed: {
        ...mapGetters(['getCompany']),
        actualClass() {
            if (this.nopx || this.icon) {
                return this.classes;
            }

            return 'px-2 ' + this.classes;
        }
    }
};
</script>
