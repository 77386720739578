import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const DEFAULT_LANG = localStorage.getItem('lang') ?? process.env.VUE_APP_DEFAULT_LANGUAGE;

//en: require('./i18n/en.json')
const locales = {
    'es-ES': require('./i18n/es-ES.json'),
    'al-AL': require('./i18n/al-AL.json'),
    'br-BR': require('./i18n/br-BR.json'),
    'cs-CH': require('./i18n/cs-CH.json'),
    'en-CA': require('./i18n/en-CA.json'),
    'en-US': require('./i18n/en-US.json'),
    'es-AR': require('./i18n/es-AR.json'),
    'es-CL': require('./i18n/es-CL.json'),
    'es-MX': require('./i18n/es-MX.json'),
    'es-PE': require('./i18n/es-PE.json'),
    'eu-ES': require('./i18n/eu-ES.json'),
    'it-IT': require('./i18n/it-IT.json'),
    'pl-PL': require('./i18n/pl-PL.json'),
    'pt-PT': require('./i18n/pt-PT.json'),
    'ro-RU': require('./i18n/ro-RU.json'),
    'rs-RS': require('./i18n/rs-RS.json'),
    'lt-LT': require('./i18n/lt-LT.json')
};

const i18n = new VueI18n({
    // https://kazupon.github.io/vue-i18n/guide/fallback.html#explicit-fallback-with-decision-maps
    fallbackLocale: {
        default: ['es-ES']
    },
    locale: DEFAULT_LANG,
    messages: locales
});

export default i18n;
