export default {
    isJumanji(game) {
        return +game?.game_type_id === 1;
    },
    isQuestions(game) {
        const gameTypeId = +game?.game_type_id;

        return gameTypeId === 2 || gameTypeId === 5;
    },
    isConstructTableDice(game) {
        return +game?.game_type_id === 3;
    },
    isResultTable(game) {
        return +game?.game_type_id === 4;
    },
    hasInfo(game) {
        if (!game?.game_type_id) return false;

        switch (+game.game_type_id) {
            case 1:
                return false; // if is a Jumanji game
            case 3:
                return true; // if is a Tablero-Dados-Preguntas game
            default:
                return false;
        }
    },

    hasCards(game) {
        if (!game?.game_type_id) return false;

        switch (+game.game_type_id) {
            case 1:
                return true; // if is a Jumanji game
            case 2:
                return true; // if is a Questions game
            case 3:
                return true; // if is a Tablero-Dados-Preguntas game
            default:
                return false;
        }
    },
    hasPlayers(game) {
        if (!game?.game_type_id) return false;

        if (this.isQuestions(game) || this.isConstructTableDice(game) || this.isResultTable(game)) return false;

        return true;
    },
    hasDateRange(game) {
        if (!game?.game_type_id) return false;

        if (this.isQuestions(game)) return true;

        return false;
    },
    hasAllowModifyAnswerAfterSubmit(game) {
        if (!game?.game_type_id) return false;

        switch (+game.game_type_id) {
            case 2:
                return true; // if is a Questions game
            default:
                return false;
        }
    },
    hasShowResultAfterSubmitOrGameEnd(game) {
        if (!game?.game_type_id) return false;

        switch (+game.game_type_id) {
            case 2:
                return true; // if is a Questions game
            default:
                return false;
        }
    },
    hasCardsNumberMax(game) {
        if (!game?.game_type_id) return false;

        if (this.isQuestions(game)) return true;

        return false;
    }
};
