import BaseApiServices from '@/services/BaseApiServices';

class GeneralAPIService extends BaseApiServices {
    async getLanguages() {
        //controller = new AbortController();
        const url = 'configs/languages';

        const data = await this.getAxiosInstance().get(url);

        return data;
    }

    async updateLanguage({ userId, language }) {
        const url = `configs/languages/change/${userId}`;

        return await this.getAxiosInstance().post(url, {
            language
        });
    }
}

export default new GeneralAPIService();
