import GeneralAPIService from '@/services/GeneralAPIService';

const state = () => ({
    lang: localStorage.getItem('lang') ?? process.env.VUE_APP_DEFAULT_LANGUAGE,
    languages: []
});

const mutations = {
    SET_LANGUAGES(state, languages) {
        state.languages = languages;
    },
    SET_LANG(state, lang) {
        state.lang = lang;
    }
};
const actions = {
    async fetchLanguages({ state, commit, dispatch }) {
        if (!state.languages.length) {
            const languages = await GeneralAPIService.getLanguages();

            commit('SET_LANGUAGES', languages.data.languages);
            // dispatch('catalog/findAllCategories', lang, { root: true })
        }
    },

    async updateLanguage({ commit, rootGetters, dispatch }, { language, updateApi = true }) {
        // Vue.prototype.$nuxt.$vuetify.lang.current
        commit('SET_LANG', language);
        window.localStorage.setItem('lang', language);
        // dispatch('catalog/findAllCategories', language, { root: true })

        if (updateApi) {
            const userId = rootGetters.getUserId;
            if (userId || userId == 0) {
                await GeneralAPIService.updateLanguage({ userId: userId, language });
            }
        }
    }
};
const getters = {
    getLang: state => {
        return state.lang;
    },
    getLangToShow: state => {
        if (state.lang === 'rs-RS') return 'uk-UA';

        return state.lang;
    },
    getLanguages: state => {
        return state.languages;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
