import axios from 'axios';
import store from '@/store';
import router from '@/router';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    timeout: 20000,
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'Access-Control-Allow-Origin': '*'
    //   withCredentials: true,
});

axiosInstance.defaults.headers['Content-Type'] = 'application/vnd.api+json';
axiosInstance.defaults.headers.Accept = 'application/vnd.api+json';

// request interceptor
axiosInstance.interceptors.request.use(
    config => {
        const token = store.getters.getToken;

        // alert(token);
        // //localStorage.getItem("access_token");
        if (token) {
            config.headers.authorization = 'Bearer ' + token;
            // config.headers.token = token;
            // config.headers["Authorization"] = token;
        } else {
            // config.headers.Authorization = token;
        }

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// response interceptor
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        // const status = err.response.status; //statusText
        if (err.response.status === 401 || err.response.status === 403) {
            store.dispatch('signOut');

            router.push({ path: '/app/sessions/sign-in' });
        } else if (err.response.status === 423) {
            store.dispatch('snackbar/error', 'Esta operación no está permitida actualmente');
        }

        return Promise.reject(err);
    }
);

export default axiosInstance;
